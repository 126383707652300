import React, { useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CaseStudyResults from '../components/CaseStudyResults';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import useScrollAnimation from '../utils/use-scrollanimation2';
import interpolateAndClamp from '../utils/interpolate-and-clamp';
import video from '../vid/test.mp4';
import video2 from '../vid/breakthrough-video-samples.mp4';

const StyledChallenge = styled.div`
  color: black;
  font-size: 32px;
  margin: 60px 0;
  padding: 0 30px;
  h1,
  h2 {
    margin-top: 0px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: 500;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  p {
    font-weight: 300;
    font-size: 1.2rem;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

const StyledResults = styled.div`
  color: black;
  font-size: 32px;
  margin: 20px 0 80px 0;
  .copyblock {
    text-align: ${(props) => props.align};
    font-weight: 300;
    font-size: 20px;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    p {
      margin: 0;
    }
  }
`;

const StyledApproach = styled.div`
  color: black;
  font-size: 32px;
  margin: 90px 0 130px 0;
  padding: 0 30px;
  position: relative;
  h2 {
    margin-top: 0px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: 500;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  .copyblock {
    font-weight: 300;
    font-size: 1.2rem;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  padding-top: 100px;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  .bgsquare {
    width: 45%;
    height: 65vw;
    max-height: 480px;
    max-width: 845px;
    background-color: rgba(207, 230, 239, 0.5);
    position: absolute;
    right: 80px;
    top: 320px;
  }
  .animated-image {
    margin: 0 auto;
    width: 100%;
    flex: 0 0 40%;
    padding-top: 190px;
    transition: all 200ms linear;
    position: relative;
  }
  .left-image {
    left: 140px;
    margin-left: 0;
  }
  .right-image {
    max-width: 720px;
    right: 140px;
    margin-right: 0;
  }
  @media (max-width: 1120px) {
    .animated-image {
      flex: 0 0 50%;
    }
    .left-image {
      left: 30px;
    }
    .right-image {
      right: 30px;
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 50px 0;
    .animated-image {
      transform: none !important;
      padding: 0 20px;
      margin: 20px auto;
      box-sizing: border-box;
      &.left-image {
        left: 0;
        right: 0;
      }
      &.right-image {
        left: 0;
        right: 0;
      }
    }
    .bgsquare {
      top: auto;
      bottom: 0;
      left: 0;
      width: 85vw;
      height: 85vw;
    }
  }
`;

const StyledWrapper2 = styled.div`
  display: flex;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  .bgsquare {
    width: 660px;
    height: 370px;
    background-color: #ffc800;
    position: absolute;
    right: -30px;
    top: 90px;
  }
  .animated-image {
    margin: 0 auto;
    width: 100%;
    flex: 0 0 40%;
    transition: all 200ms linear;
    position: relative;
    &.left-image {
      flex: 0 0 55%;
      z-index: 1;
      padding-top: 190px;
      max-width: 720px;
      left: 30px;
    }
    &.right-image {
      z-index: 0;
      right: 30px;
    }
  }
  @media (max-width: 1200px) {
    .left-image,
    .right-image {
      flex: 0 0 50%;
      max-width: 50% !important;
    }
  }
  @media (max-width: 750px) {
    flex-direction: column;
    padding: 50px 0;
    .animated-image {
      transform: none !important;
      padding: 0 20px;
      margin: 20px auto;
      box-sizing: border-box;
      flex: 0 0 100%;
      &.left-image {
        left: 0;
        right: 0;
        margin-left: auto;
        padding-top: 0;
        max-width: 100% !important;
      }
      &.right-image {
        left: 0;
        right: 0;
        margin-right: auto;
        max-width: 100% !important;
      }
    }
  }
`;

const AnimatedChlg = animated(StyledChallenge);

const CaseStudyBreakthrough = ({ className, fields }) => {
  const [dispatch] = useScrollAnimation();

  const [paralax1, setParalax1] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax2, setParalax2] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax3, setParalax3] = useSpring(() => ({ transform: 'translate3d(0px, 0px, 0px)' }));
  const [paralax4, setParalax4] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax5, setParalax5] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax6, setParalax6] = useSpring(() => ({ transform: 'translate3d(0px, 0px, 0px)' }));
  const [paralax7, setParalax7] = useSpring(() => ({ transform: 'translateY(0px)' }));

  //THIS CONTROLS THE HEADER/INTRO COPY
  const para1Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax1.start({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, 140], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE TESTIMONIAL IN THE FIRST GROUP OF IMAGES
  const para2Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax2.start({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, -100], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE BG SQUARE IN THE FIRST GROUP OF IMAGES
  const para3Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax3.start({
        transform: 'translate3d(-40px, ' + interpolateAndClamp([0, 1], [-20, -280], item.scrollPct) + 'px, 0px)',
      });
    }
  };

  //THIS CONTROLS THE RIGHT IMAGE IN THE FIRST GROUP OF IMAGES
  const para4Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax4.start({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, -150], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE LEFT IMAGE IN THE SECOND GROUP OF IMAGES
  const para5Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax5.start({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [60, -100], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE BG SQUARE IN THE SECOND GROUP OF IMAGES
  const para6Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax6.start({
        transform: 'translate3d(-380px' + interpolateAndClamp([0, 1], [-30, -90], item.scrollPct) + 'px, 0px)',
      });
    }
  };

  //THIS CONTROLS THE RIGHT IMAGE IN THE SECOND GROUP OF IMAGES
  const para7Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax7.start({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [-140, 100], item.scrollPct) + 'px)',
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'registerItem',
      start: 70,
      end: 950,
      id: 'paralax-1',
      onUpdate: para1Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1200,
      id: 'paralax-2',
      onUpdate: para2Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1200,
      id: 'paralax-3',
      onUpdate: para3Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1200,
      id: 'paralax-4',
      onUpdate: para4Move,
    });
    dispatch({
      type: 'registerItem',
      start: 1600,
      end: 2900,
      id: 'paralax-5',
      onUpdate: para5Move,
    });
    dispatch({
      type: 'registerItem',
      start: 1600,
      end: 2900,
      id: 'paralax-6',
      onUpdate: para6Move,
    });
    dispatch({
      type: 'registerItem',
      start: 1600,
      end: 2900,
      id: 'paralax-7',
      onUpdate: para7Move,
    });
  }, [dispatch]);

  return (
    <>
      <div className="logo" style={{ maxWidth: '550px' }}>
        <GatsbyImage image={fields.logo_image.childImageSharp.gatsbyImageData} alt={fields.logo_alt} />
      </div>

      <AnimatedChlg style={paralax1}>
        <h1>Breakthrough's Integrated Marketing Challenge</h1>
        <p>{fields.challenge}</p>
      </AnimatedChlg>

      <StyledWrapper>
        <animated.div className="bgsquare" style={paralax3} />

        {/* Testimonial */}
        <animated.div className="animated-image left-image" style={paralax2}>
          <GatsbyImage image={fields.images[0].img.childImageSharp.gatsbyImageData} alt={fields.images[0].alt} />
        </animated.div>

        <animated.div className="animated-image right-image" style={paralax4}>
          <video src={video2} muted="muted" loop="loop" autoPlay="autoplay" style={{ width: '100%' }} />
        </animated.div>
      </StyledWrapper>

      <StyledApproach>
        <h2>Our Integrated Approach</h2>
        <div className="copyblock" dangerouslySetInnerHTML={{ __html: fields.integrated_approach }} />
      </StyledApproach>

      <GatsbyImage image={fields.images[4].img.childImageSharp.gatsbyImageData} alt={fields.images[4].alt} />

      <StyledWrapper2>
        <animated.div className="animated-image left-image" style={paralax5}>
          <video src={video} muted="muted" loop="loop" autoPlay="autoplay" style={{ width: '100%' }} />
        </animated.div>

        <animated.div className="bgsquare" style={paralax6} />

        <animated.div className="animated-image right-image" style={paralax7}>
          <GatsbyImage image={fields.images[3].img.childImageSharp.gatsbyImageData} alt={fields.images[3].alt} />
        </animated.div>
      </StyledWrapper2>

      <StyledResults align={fields.results_left ? 'left' : 'center'}>
        <CaseStudyResults props={fields} numrows={5} />
      </StyledResults>
    </>
  );
};

export default CaseStudyBreakthrough;
