import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import CaseStudyBreakthrough from '../components/CaseStudyBreakthrough';
import CaseStudyOhSnap from '../components/CaseStudyOhSnap';
import CaseStudyFreshLock from '../components/CaseStudyFreshLock';
import CaseStudyUnison from '../components/CaseStudyUnison';
import CaseStudyKaytee from '../components/CaseStudyKaytee';
import CaseStudyBeefitarian from '../components/CaseStudyBeefitarian';
import Layout from '../components/Layout';
import { navigate } from '@reach/router';

const PageTemplate = ({ className, fields }) => {
  return (
    <>
      <Hero
        heroImg={fields.acf.hero_image.childImageSharp.gatsbyImageData}
        labelClass="case-study"
        label="Case Study"
        className="case-hero"
        alt_text={fields.hero_alt}
      />

      <div className="case-study-content">
        {fields.title == 'Breakthrough' && <CaseStudyBreakthrough className={className} fields={fields} />}
        {fields.title == 'Fresh-Lock' && <CaseStudyFreshLock className={className} fields={fields} />}
        {fields.title == 'Kaytee' && <CaseStudyKaytee className={className} fields={fields} />}
        {fields.title == 'Oh Snap!' && <CaseStudyOhSnap className={className} fields={fields} />}
        {fields.title == 'Unison' && <CaseStudyUnison className={className} fields={fields} />}
        {fields.title == 'Beefitarian' && <CaseStudyBeefitarian className={className} fields={fields} />}
        <div style={{ textAlign: 'center' }}>
          <Link
            className="cta"
            to="/case-studies/"
            style={{ color: 'black', borderColor: 'black' }}
            onClick={(e) => {
              e.preventDefault();
              navigate('/case-studies/#case-studies');
            }}
          >
            See More Case Studies
          </Link>
        </div>
      </div>
    </>
  );
};

const Page = ({ data }) => {
  if (data.allAllCaseStudyPagesHJson.edges[0].node) {
    const page = data.allAllCaseStudyPagesHJson.edges[0].node;
    const meta = data.site.siteMetadata;

    return (
      <Layout>
        <Helmet>
          <title>{page.meta_title}</title>
          <meta name="description" content={page.meta_desc} />
          <meta property="og:title" content={page.meta_title} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={page.meta_desc} />
          {page.meta_ogimg && <meta property="og:image" content={meta.siteUrl + page.meta_ogimg.publicURL} />}
          <meta property="og:url" content={meta.siteUrl + '/case-studies/' + page.path + '/'} />
        </Helmet>
        <PageTemplate className="casestudy-page" fields={page} />
      </Layout>
    );
  } else {
    return <></>;
  }
};

export default Page;

export const pageQuery = graphql`
  query CaseStudyPageById($id: String!) {
    allAllCaseStudyPagesHJson(filter: { id: { eq: $id } }) {
      edges {
        node {
          path
          title
          meta_title
          meta_desc
          meta_ogimg {
            publicURL
          }
          lede
          challenge
          results_centered
          results_left
          testimonial
          testimonial_label
          integrated_approach
          acf {
            hero_image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 1920)
              }
            }
          }
          hero_alt
          logo_image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 600)
            }
          }
          logo_alt
          images {
            img {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 1920)
              }
            }
            alt
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
