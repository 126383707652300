import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useSpring, animated as a } from 'react-spring';

const ScrollerDiv = styled.div`
  z-index: 33;
  flex: 0 1 40px;
  height: 80vh;
  position: sticky;
  top: 80px;

  .bar {
    position: relative;
    height: 100%;
    width: 1px;
    background-color: #009bce;
  }

  .scroller {
    position: absolute;
    bottom: 0;
    height: 30%;
    width: 1px;
    background: white;
  }

  .label {
    position: absolute;
    transform-origin: bottom left;
    transform: rotate(-90deg);
    bottom: 0;
    width: 200px;
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 0.9rem;
    letter-spacing: 1.5px;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  font-family: 'Oswald';
  h2 {
    text-align: center;
    flex: 0 0 50%;
    position: sticky;
    height: 90px;
    top: 40vh;
    padding-bottom: 20vh;
    font-weight: 400;
    color: #0095c8;
    font-family: 'Oswald';
    text-transform: none;
  }
  & > div {
    flex: 0 0 50%;
    p {
      padding: 40px 0;
      font-size: 1rem;
      strong {
        display: block;
        font-size: 2rem;
        line-height: 2rem;
        color: #0095c8;
        font-weight: 600;
      }
    }
    &.ready p {
      transform: translate(140px, 20px);
      opacity: 0;
      transition: all 440ms ease-out;
    }
    &.active1 p:nth-child(1) {
      transform: translate(0px, 0px);
      opacity: 1;
    }
    &.active2 p:nth-child(2) {
      transform: translate(0px, 0px);
      opacity: 1;
    }
    &.active3 p:nth-child(3) {
      transform: translate(0px, 0px);
      opacity: 1;
    }
    &.active4 p:nth-child(4) {
      transform: translate(0px, 0px);
      opacity: 1;
    }
    &.active5 p:nth-child(5) {
      transform: translate(0px, 0px);
      opacity: 1;
    }
    &.active6 p:nth-child(6) {
      transform: translate(0px, 0px);
      opacity: 1;
    }
    &.active7 p:nth-child(7) {
      transform: translate(0px, 0px);
      opacity: 1;
    }
    &.active8 p:nth-child(8) {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
  @media (max-width: 981px) {
    flex-direction: column;
    overflow: hidden;
    h2 {
      z-index: 99;
      top: 0;
      padding-top: 0;
      position: relative;
      padding-bottom: 0;
    }
  }
`;

const Scroller = () => {
  const ref = useRef();

  const [{ st }, set] = useSpring(() => ({ st: 0, config: { mass: 1, tension: 265, friction: 25 } }));
  const interpPos = st.to((st) => `translateY(-${st}px)`);
  const displayText = st
    .to({ extrapolate: 'clamp', range: [0, 201, 409], output: [0, 1, 2] })
    .to((x) => ['Problem Solving', 'Partnership', 'Results'][Math.floor(x)]);
  // const onScroll = useCallback(yp => { }, [0])

  useEffect(() => {
    let yPos = 0;
    const startPos = ref.current.parentElement.offsetTop;
    const tick = () => {
      timerID = window.requestAnimationFrame(tick);
      if (yPos !== window.scrollY) {
        if (window.scrollY > startPos) set({ st: (window.scrollY - startPos) / 3.4 });
        else set({ st: 0 });
        yPos = window.scrollY;
      }
    };
    let timerID = window.requestAnimationFrame(tick);
    return function cleanup() {
      window.cancelAnimationFrame(timerID);
    };
  });
  return (
    <ScrollerDiv className="xyz" ref={ref}>
      <div className="bar">
        <a.div className="scroller" style={{ transform: interpPos }}>
          <a.div className="label">{displayText}</a.div>
        </a.div>
      </div>
    </ScrollerDiv>
  );
};

const CaseStudyResults = ({ props, numrows }) => {
  const [active, setActive] = useState(' ready');
  const ref = useRef();

  useEffect(() => {
    let yPos = 0;
    const offset = 80;
    const itemHeight = ref.current.firstChild.offsetHeight;
    const startPos = ref.current.offsetTop + itemHeight / 2 + offset - window.innerHeight;
    const tick = () => {
      timerID = window.requestAnimationFrame(tick);
      if (yPos !== window.scrollY) {
        if (window.scrollY > startPos) {
          let y = 0;
          let curActive = ' ready';
          while (y < numrows) {
            if (window.scrollY > startPos + itemHeight * y) {
              curActive += ' active' + (y + 1);
            }
            y++;
          }
          setActive(curActive);
        } else if (active !== ' ready') {
          setActive(' ready');
        }
        yPos = window.scrollY;
      }
    };
    let timerID = window.requestAnimationFrame(tick);
    return function cleanup() {
      window.cancelAnimationFrame(timerID);
    };
  }, [ref, active]);

  return (
    <StyledWrapper>
      <h2>Results</h2>

      <div
        ref={ref}
        className={'copyblock' + active}
        dangerouslySetInnerHTML={{
          __html: props.results_left ? props.results_left : props.results_centered,
        }}
      />
    </StyledWrapper>
  );
};
export default CaseStudyResults;
