import React, { useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CaseStudyResults from '../components/CaseStudyResults';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import useScrollAnimation from '../utils/use-scrollanimation2';
import interpolateAndClamp from '../utils/interpolate-and-clamp';

const StyledChallenge = styled.div`
  color: black;
  font-size: 32px;
  margin: 60px 0;
  padding: 0 30px;
  h1,
  h2 {
    margin-top: 0px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: 500;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  p {
    font-weight: 300;
    font-size: 1.2rem;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

const StyledResults = styled.div`
  color: black;
  font-size: 32px;
  margin: 20px 0 80px 0;
  .copyblock {
    text-align: ${(props) => props.align};
    font-weight: 300;
    font-size: 20px;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    p {
      margin: 0;
    }
  }
`;

const StyledApproach = styled.div`
  color: black;
  font-size: 32px;
  margin: 90px 0 130px 0;
  padding: 0 30px;
  position: relative;
  h2 {
    margin-top: 0px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: 500;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  .copyblock {
    font-weight: 300;
    font-size: 1.2rem;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  padding: 100px 0;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  .bgsquare {
    width: 45%;
    height: 65vw;
    max-height: 480px;
    max-width: 270px;
    background-color: rgba(207, 230, 239, 0.5);
    position: absolute;
    right: 260px;
    top: 320px;
  }
  .animated-image {
    margin: 0 auto;
    width: 100%;
    flex: 0 0 40%;
    transition: all 200ms linear;
    position: relative;
  }
  .left-image {
    padding-top: 190px;
    left: 140px;
  }
  .two-image-container {
    max-width: 520px;
    width: 100%;
    margin: 0 auto;
    flex: 0 0 40%;
    padding-top: 190px;
    position: relative;
    right: 140px;
    .right-image-1 {
      padding-top: 190px;
      top: 40px;
      left: 0;
      position: absolute;
      max-width: 296px;
    }
    .right-image-2 {
      position: absolute;
      top: 350px;
      right: 0;
      max-width: 305px;
    }
  }
  @media (max-width: 1500px) {
    .left-image {
      margin-left: 0;
    }
    .two-image-container {
      margin-right: 0;
    }
  }
  @media (max-width: 1200px) {
    .animated-image {
      max-width: 50%;
      flex: 0 0 50%;
    }
    .left-image {
      left: 50px;
      margin: 0;
      max-width: 100% !important;
    }
    .two-image-container {
      right: 50px;
      max-width: 100% !important;
      flex: 0 0 50%;
      margin: 0;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 50px 0;
    .animated-image {
      transform: none !important;
      padding: 0 20px;
      margin: 20px auto;
      box-sizing: border-box;
      flex: 0 0 100%;
      &.left-image {
        left: 0;
        right: 0;
        margin-left: auto;
        padding-top: 0;
      }
    }
    .two-image-container {
      left: 0;
      right: 0;
      margin-right: auto;
      max-width: 100% !important;
      padding-top: 0;
      .animated-image {
        position: relative;
        top: auto;
        left: auto;
        padding-top: 0;
        margin-bottom: 40px;
      }
    }
    .bgsquare {
      top: auto;
      bottom: 0;
      left: 0;
      width: 85vw;
      height: 85vw;
    }
  }
`;

const StyledWrapper2 = styled.div`
  display: flex;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  .bgsquare {
    width: 250px;
    height: 470px;
    background-color: rgba(207, 230, 239, 0.5);
    position: absolute;
    left: 400;
    top: 180px;
  }
  .animated-image {
    margin: 0 auto;
    width: 100%;
    flex: 0 0 40%;
    transition: all 200ms linear;
    position: relative;
    &.left-image {
      max-width: 220px;
      flex: 0 0 55%;
      z-index: 1;
      left: 140px;
      padding-top: 190px;
    }
    &.right-image {
      z-index: 0;
      right: 80px;
    }
  }
  @media (max-width: 1170px) {
    .bgsquare {
      right: 100px;
    }
    .animated-image {
      &.left-image {
        margin-left: 0;
        flex: 0 0 50%;
      }
      &.right-image {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 750px) {
    flex-direction: column;
    padding: 50px 0;
    .animated-image {
      transform: none !important;
      padding: 0 20px;
      margin: 20px auto;
      box-sizing: border-box;
      flex: 0 0 100%;
      &.left-image {
        left: 0;
        right: 0;
        margin-left: auto;
        padding-top: 0;
      }
      &.right-image {
        left: 0;
        right: 0;
        margin-right: auto;
        max-width: 100% !important;
      }
    }
    .bgsquare {
      top: auto;
      bottom: 0;
      left: 0;
      width: 85vw;
      height: 85vw;
    }
  }
`;

const AnimatedChlg = animated(StyledChallenge);

const CaseStudyKaytee = ({ className, fields }) => {
  const [dispatch] = useScrollAnimation();

  const [paralax1, setParalax1] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax2, setParalax2] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax3, setParalax3] = useSpring(() => ({ transform: 'translate3d(0px, 0px, 0px)' }));
  const [paralax4, setParalax4] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax5, setParalax5] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax6, setParalax6] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax7, setParalax7] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax8, setParalax8] = useSpring(() => ({ transform: 'translateY(0px)' }));

  //THIS CONTROLS THE HEADER/INTRO COPY
  const para1Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax1({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, 140], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE LEFT IMAGE IN THE FIRST GROUP OF IMAGES
  const para2Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax2({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, -100], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE BG SQUARE IN THE FIRST GROUP OF IMAGES
  const para3Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax3({
        transform: 'translate3d(-60px, ' + interpolateAndClamp([0, 1], [60, -200], item.scrollPct) + 'px, 0px)',
      });
    }
  };

  //THIS CONTROLS THE RIGHT IMAGE IN THE FIRST GROUP OF IMAGES
  const para4Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax4({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, -150], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE SECOND RIGHT IMAGE IN THE FIRST GROUP OF IMAGES
  const para5Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax5({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, 80], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE LEFT IMAGE IN THE SECOND GROUP OF IMAGES
  const para6Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax6({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [55, -205], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE BG SQUARE IN THE SECOND GROUP OF IMAGES
  const para7Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax7({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [-55, -95], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE RIGHT IMAGE IN THE SECOND GROUP OF IMAGES
  const para8Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax8({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [-100, 40], item.scrollPct) + 'px)',
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'registerItem',
      start: 70,
      end: 950,
      id: 'paralax-1',
      onUpdate: para1Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1200,
      id: 'paralax-2',
      onUpdate: para2Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1200,
      id: 'paralax-3',
      onUpdate: para3Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1200,
      id: 'paralax-4',
      onUpdate: para4Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1200,
      id: 'paralax-5',
      onUpdate: para5Move,
    });
    dispatch({
      type: 'registerItem',
      start: 1600,
      end: 2900,
      id: 'paralax-6',
      onUpdate: para6Move,
    });
    dispatch({
      type: 'registerItem',
      start: 1600,
      end: 2900,
      id: 'paralax-7',
      onUpdate: para7Move,
    });
    dispatch({
      type: 'registerItem',
      start: 1600,
      end: 2900,
      id: 'paralax-8',
      onUpdate: para8Move,
    });
  }, [dispatch]);

  return (
    <>
      <div className="logo" style={{ maxWidth: '279px' }}>
        <GatsbyImage image={fields.logo_image.childImageSharp.gatsbyImageData} alt={fields.logo_alt} />
      </div>

      <AnimatedChlg style={paralax1}>
        <h1>Kaytee's Marketing Challenge</h1>
        <p>{fields.challenge}</p>
      </AnimatedChlg>

      <StyledWrapper>
        <animated.div className="bgsquare" style={paralax3} />

        {/* Testimonial */}
        <animated.div className="animated-image left-image" style={paralax2}>
          <GatsbyImage image={fields.images[0].img.childImageSharp.gatsbyImageData} alt={fields.images[0].alt} />
        </animated.div>

        <div className="two-image-container">
          <animated.div className="animated-image right-image-1" style={paralax4}>
            <GatsbyImage image={fields.images[1].img.childImageSharp.gatsbyImageData} alt={fields.images[1].alt} />
          </animated.div>
          <animated.div className="animated-image right-image-2" style={paralax5}>
            <GatsbyImage image={fields.images[2].img.childImageSharp.gatsbyImageData} alt={fields.images[2].alt} />
          </animated.div>
        </div>
      </StyledWrapper>

      <StyledApproach>
        <h2>Our Integrated Approach</h2>
        <div className="copyblock" dangerouslySetInnerHTML={{ __html: fields.integrated_approach }} />
      </StyledApproach>

      <StyledWrapper2>
        <animated.div className="animated-image left-image" style={paralax6}>
          <GatsbyImage image={fields.images[3].img.childImageSharp.gatsbyImageData} alt={fields.images[3].alt} />
        </animated.div>

        <animated.div className="bgsquare" style={paralax7} />

        <animated.div className="animated-image right-image" style={paralax8}>
          <GatsbyImage image={fields.images[4].img.childImageSharp.gatsbyImageData} alt={fields.images[4].alt} />
        </animated.div>
      </StyledWrapper2>

      <StyledResults align={fields.results_left ? 'left' : 'center'}>
        <CaseStudyResults props={fields} numrows={5} />
      </StyledResults>
    </>
  );
};

export default CaseStudyKaytee;
