import React, { useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CaseStudyResults from './CaseStudyResults';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import useScrollAnimation from '../utils/use-scrollanimation2';
import interpolateAndClamp from '../utils/interpolate-and-clamp';
import Audio from './audio';
import AudioFile from '../../static/audio/beefitarian-jingle.mp3';

const StyledChallenge = styled.div`
  color: black;
  font-size: 32px;
  margin: 60px 0;
  padding: 0 30px;
  h1,
  h2 {
    margin-top: 0px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: 500;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  p {
    font-weight: 300;
    font-size: 1.2rem;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

const StyledResults = styled.div`
  color: black;
  font-size: 32px;
  margin: 20px 0 80px 0;
  background: white;
  .copyblock {
    text-align: ${(props) => props.align};
    font-weight: 300;
    font-size: 20px;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    p {
      margin: 0;
    }
  }
`;

const StyledApproach = styled.div`
  color: black;
  font-size: 32px;
  margin: 60px 0 30px 0;
  padding: 0 30px;
  position: relative;
  h2 {
    margin-top: 0px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: 500;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  .copyblock {
    font-weight: 300;
    font-size: 1.2rem;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  padding: 290px 0 100px;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  .bgsquare {
    width: 45%;
    height: 65vw;
    max-height: 480px;
    max-width: 845px;
    background-color: rgba(207, 230, 239, 0.5);
    position: absolute;
    right: 80px;
    top: 320px;
  }
  .animated-image {
    margin: 0 auto;
    width: 100%;
    flex: 0 0 40%;
    transition: all 200ms linear;
    position: relative;
  }
  .left-image {
    left: 140px;
    margin-left: 0;
  }
  .right-image {
    max-width: 766px;
    overflow: hidden;
    right: 120px;
    margin-right: 0;
    .facebook-scroll-container {
      position: absolute;
      overflow: hidden;
      top: 10%;
      width: 72%;
      height: 75%;
      left: 14%;
      background-color: #b6b6b6;
      > div {
        position: absolute;
        max-width: 600px;
        width: calc(100% + 28px);
        left: -28px;
      }
      .image-1 {
        top: 0px;
      }
      @media (max-width: 750px) {
        width: 68%;
        left: 16%;
      }
    }
  }
  @media (max-width: 1170px) {
    .bgsquare {
      height: 45vw;
    }
  }
  @media (max-width: 1000px) {
    .animated-image {
      flex: 0 0 50%;
      &.left-image {
        left: 20px;
      }
      &.right-image {
        right: 0;
      }
    }
  }
  @media (max-width: 750px) {
    flex-direction: column;
    padding: 50px 0;
    .animated-image {
      transform: none !important;
      padding: 0 20px;
      margin: 20px auto;
      box-sizing: border-box;
      &.left-image {
        left: 0;
        right: 0;
      }
      &.right-image {
        left: 0;
        right: 0;
      }
    }
    .bgsquare {
      top: auto;
      bottom: 0;
      left: 0;
      width: 85vw;
      height: 85vw;
    }
  }
`;

const StyledWrapper2 = styled.div`
  display: flex;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  overflow: hidden;
  @media (min-width: 751px) {
    height: 1500px;
  }
  .animated-image {
    margin: 0 auto;
    max-width: 390px;
    width: 100%;
    transition: all 200ms linear;
    position: relative;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    padding: 50px 0;
    .animated-image {
      transform: none !important;
      padding: 0 20px;
      margin: 20px auto;
      box-sizing: border-box;
      flex: 0 0 100%;
    }
  }
`;

const AnimatedChlg = animated(StyledChallenge);

const CaseStudyBeefitarian = ({ className, fields }) => {
  const [dispatch] = useScrollAnimation();

  const [paralax1, setParalax1] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax2, setParalax2] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax3, setParalax3] = useSpring(() => ({ transform: 'translate3d(0px, 0px, 0px)' }));
  const [paralax4, setParalax4] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax5, setParalax5] = useSpring(() => ({ transform: 'translateY(0px)' }));

  const [paralaxSocial1, setParalaxSocial1] = useSpring(() => ({ transform: 'translateY(0px)' }));

  //THIS CONTROLS THE HEADER/INTRO COPY
  const para1Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax1.start({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, 140], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE TESTIMONIAL IN THE FIRST GROUP OF IMAGES
  const para2Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax2.start({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, -100], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE BG SQUARE IN THE FIRST GROUP OF IMAGES
  const para3Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax3.start({
        transform: 'translate3d(-40px, ' + interpolateAndClamp([0, 1], [-120, -210], item.scrollPct) + 'px, 0px)',
      });
    }
  };

  //THIS CONTROLS THE FIRST AND THIRD COLUMN IN THE SOCIAL POSTS
  const para4Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax4.start({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, 150], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE SECOND AND FOURTH COLUMN IN THE SOCIAL POSTS
  const para5Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax5.start({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [150, 0], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE WEBSITE SLIDE IN THE LAPTOP
  const social1Move = (item) => {
    if (typeof item.scrollPct !== 'undefinded') {
      setParalaxSocial1.start({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [-244, -800], item.scrollPct) + 'px)',
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'registerItem',
      start: 70,
      end: 950,
      id: 'paralax-1',
      onUpdate: para1Move,
    });
    dispatch({
      type: 'registerItem',
      start: 300,
      end: 1200,
      id: 'paralax-2',
      onUpdate: para2Move,
    });
    dispatch({
      type: 'registerItem',
      start: 300,
      end: 1200,
      id: 'paralax-3',
      onUpdate: para3Move,
    });
    dispatch({
      type: 'registerItem',
      start: 800,
      end: 2900,
      id: 'paralax-4',
      onUpdate: para4Move,
    });
    dispatch({
      type: 'registerItem',
      start: 800,
      end: 2900,
      id: 'paralax-5',
      onUpdate: para5Move,
    });
    dispatch({
      type: 'registerItem',
      start: 0,
      end: 1600,
      id: 'social-1',
      onUpdate: social1Move,
    });
  }, [dispatch]);

  return (
    <>
      <div className="logo" style={{ maxWidth: '375px' }}>
        <GatsbyImage image={fields.logo_image.childImageSharp.gatsbyImageData} alt={fields.logo_alt} />
      </div>

      <AnimatedChlg style={paralax1}>
        <h1>Launching the Beefitarian Brand</h1>
        <p>{fields.challenge}</p>
      </AnimatedChlg>

      <StyledWrapper>
        <animated.div className="bgsquare" style={paralax3} />

        {/* Testimonial */}
        <animated.div className="left-image animated-image" style={paralax2}>
          <GatsbyImage image={fields.images[0].img.childImageSharp.gatsbyImageData} alt={fields.images[0].alt} />
        </animated.div>

        <animated.div className="right-image animated-image" style={paralax4}>
          <GatsbyImage
            image={fields.images[1].img.childImageSharp.gatsbyImageData}
            alt={fields.images[1].alt}
            style={{ zIndex: 1 }}
          />
          <div className="facebook-scroll-container">
            <animated.div className="image-1" style={paralaxSocial1}>
              <GatsbyImage image={fields.images[2].img.childImageSharp.gatsbyImageData} alt={fields.images[2].alt} />
            </animated.div>
          </div>
        </animated.div>
      </StyledWrapper>

      <StyledApproach>
        <h2>Our Integrated Approach</h2>
        <div className="copyblock" dangerouslySetInnerHTML={{ __html: fields.integrated_approach }} />
      </StyledApproach>

      <StyledWrapper2>
        <animated.div className="animated-image" style={paralax4}>
          <GatsbyImage image={fields.images[3].img.childImageSharp.gatsbyImageData} alt={fields.images[3].alt} />
        </animated.div>

        <animated.div className="animated-image" style={paralax4}>
          <GatsbyImage image={fields.images[4].img.childImageSharp.gatsbyImageData} alt={fields.images[4].alt} />
        </animated.div>

        <animated.div className="animated-image" style={paralax5}>
          <GatsbyImage image={fields.images[5].img.childImageSharp.gatsbyImageData} alt={fields.images[5].alt} />
        </animated.div>

        <animated.div className="animated-image" style={paralax5}>
          <GatsbyImage image={fields.images[6].img.childImageSharp.gatsbyImageData} alt={fields.images[6].alt} />
        </animated.div>

        <animated.div className="animated-image" style={paralax5}>
          <Audio src={AudioFile}></Audio>
        </animated.div>

        <animated.div className="animated-image" style={paralax4}>
          <GatsbyImage image={fields.images[7].img.childImageSharp.gatsbyImageData} alt={fields.images[7].alt} />
        </animated.div>

        <animated.div className="animated-image" style={paralax4}>
          <GatsbyImage image={fields.images[8].img.childImageSharp.gatsbyImageData} alt={fields.images[8].alt} />
        </animated.div>

        <animated.div className="animated-image" style={paralax5}>
          <GatsbyImage image={fields.images[9].img.childImageSharp.gatsbyImageData} alt={fields.images[9].alt} />
        </animated.div>

        <animated.div className="animated-image" style={paralax5}>
          <GatsbyImage image={fields.images[10].img.childImageSharp.gatsbyImageData} alt={fields.images[10].alt} />
        </animated.div>
      </StyledWrapper2>

      <StyledResults align={fields.results_left ? 'left' : 'center'}>
        <CaseStudyResults props={fields} numrows={4} />
      </StyledResults>
    </>
  );
};

export default CaseStudyBeefitarian;
