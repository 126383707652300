import React from 'react';
import styled from 'styled-components';

const StyledAudio = styled.div`
  width: 100%;
  color: black;
  margin-top: 100px;
  audio {
    display: block;
    margin: 0 auto;
    max-width: 314px;
    width: 100%;
  }
`;

const Audio = ({ src }) => (
  <StyledAudio>
    <audio controls controlsList="nodownload" className="audio">
      <source src={src} type="audio/mpeg"></source>
    </audio>
  </StyledAudio>
);

export default Audio;
