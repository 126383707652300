import React, { useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CaseStudyResults from '../components/CaseStudyResults';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import useScrollAnimation from '../utils/use-scrollanimation2';
import interpolateAndClamp from '../utils/interpolate-and-clamp';

const StyledChallenge = styled.div`
  color: black;
  font-size: 32px;
  margin: 60px 0;
  padding: 0 30px;
  h1,
  h2 {
    margin-top: 0px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: 500;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  p {
    font-weight: 300;
    font-size: 1.2rem;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

const StyledResults = styled.div`
  color: black;
  font-size: 32px;
  margin: 20px 0 80px 0;
  .copyblock {
    text-align: ${(props) => props.align};
    font-weight: 300;
    font-size: 20px;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    p {
      margin: 0;
    }
  }
`;

const StyledApproach = styled.div`
  color: black;
  font-size: 32px;
  margin: 60px 0 30px 0;
  padding: 0 30px;
  position: relative;
  h2 {
    margin-top: 0px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: 500;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  .copyblock {
    font-weight: 300;
    font-size: 1.2rem;
    max-width: 1000px;
    line-height: 2;
    margin: 0 auto;
    letter-spacing: 0.9px;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  padding: 100px 0;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  .bgsquare {
    width: 45%;
    height: 65vw;
    max-height: 480px;
    max-width: 845px;
    background-color: rgba(207, 230, 239, 0.5);
    position: absolute;
    right: 80px;
    top: 320px;
  }
  .animated-image {
    margin: 0 auto;
    width: 100%;
    flex: 0 0 40%;

    transition: all 200ms linear;
    position: relative;
    &.left-image {
      padding-top: 190px;
      left: 140px;
      margin-left: 0;
    }

    &.right-image {
      max-width: 370px;
      overflow: hidden;
      right: 120px;
      margin-right: 0;
      .facebook-scroll-container {
        position: absolute;
        overflow: hidden;
        top: 30px;
        width: 79%;
        height: 630px;
        left: 47px;
        background-color: #b6b6b6;
        > div {
          position: absolute;
          width: 100%;
          max-width: 292px;
        }
        .image-1 {
          top: -80px;
        }
        .image-2 {
          top: 340px;
        }
        .image-3 {
          top: 795px;
        }
      }
    }
    @media (max-width: 925px) {
      flex: 0 0 50%;
      &.left-image {
        left: 20px;
      }
      &.right-image {
        right: 20px;
      }
    }
  }
  @media (max-width: 740px) {
    flex-direction: column;
    .animated-image {
      transform: none !important;
      margin: 20px auto;
      box-sizing: border-box;
      &.left-image {
        left: 0;
        right: 0;
        padding-top: 0;
        padding: 0 20px;
      }
      &.right-image {
        right: auto;
        width: 100%;
        margin: 0 auto;
      }
    }
    .bgsquare {
      top: auto;
      bottom: 0;
      left: 0;
      width: 85vw;
      height: 85vw;
    }
  }
`;

const StyledWrapper2 = styled.div`
  display: flex;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  .bgsquare {
    width: 660px;
    height: 370px;
    background-color: rgba(207, 230, 239, 0.5);
    position: absolute;
    right: -30px;
    top: 90px;
  }
  .animated-image {
    margin: 0 auto;
    width: 100%;
    position: relative;
    &.left-image {
      flex: 0 0 40%;
      padding-top: 190px;
      z-index: 1;
      max-width: 720px;
      left: 30px;
      .left-two {
        margin: 0 auto;
        width: 100%;
        position: absolute;
        top: 200px;
        left: 80px;
        z-index: 1;
      }
    }
    &.right-image {
      flex: 0 0 55%;
      z-index: 0;
      right: 30px;
    }
  }
  @media (max-width: 1170px) {
    .bgsquare {
      right: 100px;
    }
    .animated-image {
      flex: 0 0 50% !important;
      &.left-image {
        margin-left: 0;
        .left-two {
          margin-left: 0;
          left: 50px;
        }
      }
      &.right-image {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 740px) {
    flex-direction: column;
    .animated-image {
      transform: none !important;
      margin: 20px auto;
      box-sizing: border-box;
      padding: 0 20px;
      &.left-image {
        left: 0;
        right: 0;
        padding-top: 0;
        .left-two {
          position: relative;
          top: auto;
          left: auto;
          padding: 0;
        }
      }
      &.right-image {
        right: auto;
        width: 100%;
        margin: 0 auto;
      }
    }
    .bgsquare {
      top: auto;
      bottom: 0;
      left: 0;
      width: 85vw;
      height: 85vw;
    }
  }
`;

const AnimatedChlg = animated(StyledChallenge);

const CaseStudyOhSnap = ({ className, fields }) => {
  const [dispatch] = useScrollAnimation();

  const [paralax1, setParalax1] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax2, setParalax2] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax3, setParalax3] = useSpring(() => ({ transform: 'translate3d(0px, 0px, 0px)' }));
  const [paralax4, setParalax4] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax5, setParalax5] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax6, setParalax6] = useSpring(() => ({ transform: 'translate3d(0px, 0px, 0px)' }));
  const [paralax7, setParalax7] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralax8, setParalax8] = useSpring(() => ({ transform: 'translateY(0px)' }));

  const [paralaxSocial1, setParalaxSocial1] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralaxSocial2, setParalaxSocial2] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralaxSocial3, setParalaxSocial3] = useSpring(() => ({ transform: 'translateY(0px)' }));

  //THIS CONTROLS THE HEADER/INTRO COPY
  const para1Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax1({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, 140], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE TESTIMONIAL IN THE FIRST GROUP OF IMAGES
  const para2Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax2({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, -120], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE BG SQUARE IN THE FIRST GROUP OF IMAGES
  const para3Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax3({
        transform: 'translate3d(-40px, ' + interpolateAndClamp([0, 1], [-20, -280], item.scrollPct) + 'px, 0px)',
      });
    }
  };

  //THIS CONTROLS THE RIGHT IMAGE IN THE FIRST GROUP OF IMAGES
  const para4Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax4({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, -150], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE LEFT IMAGE IN THE SECOND GROUP OF IMAGES
  const para5Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax5({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [20, -180], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE BG SQUARE IN THE SECOND GROUP OF IMAGES
  const para6Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax6({
        transform: 'translate3d(-380px' + interpolateAndClamp([0, 1], [-60, -90], item.scrollPct) + 'px, 0px)',
      });
    }
  };

  //THIS CONTROLS THE SECOND LEFT IMAGE IN THE SECOND GROUP OF IMAGES
  const para7Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax7({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [160, 200], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS THE RIGHT IMAGE IN THE SECOND GROUP OF IMAGES
  const para8Move = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setParalax8({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [-100, 50], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS SLIDE 1 IN THE PHONE
  const social1Move = (item) => {
    if (typeof item.scrollPct !== 'undefinded') {
      setParalaxSocial1({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, -600], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS SLIDE 2 IN THE PHONE
  const social2Move = (item) => {
    if (typeof item.scrollPct !== 'undefinded') {
      setParalaxSocial2({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, -600], item.scrollPct) + 'px)',
      });
    }
  };

  //THIS CONTROLS SLIDE 3 IN THE PHONE
  const social3Move = (item) => {
    if (typeof item.scrollPct !== 'undefinded') {
      setParalaxSocial3({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [0, -600], item.scrollPct) + 'px)',
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'registerItem',
      start: 100,
      end: 1100,
      id: 'paralax-1',
      onUpdate: para1Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1600,
      id: 'paralax-2',
      onUpdate: para2Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1600,
      id: 'paralax-3',
      onUpdate: para3Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1600,
      id: 'paralax-4',
      onUpdate: para4Move,
    });
    dispatch({
      type: 'registerItem',
      start: 1600,
      end: 2900,
      id: 'paralax-5',
      onUpdate: para5Move,
    });
    dispatch({
      type: 'registerItem',
      start: 1600,
      end: 2900,
      id: 'paralax-6',
      onUpdate: para6Move,
    });
    dispatch({
      type: 'registerItem',
      start: 1600,
      end: 2900,
      id: 'paralax-7',
      onUpdate: para7Move,
    });
    dispatch({
      type: 'registerItem',
      start: 1600,
      end: 2900,
      id: 'paralax-8',
      onUpdate: para8Move,
    });

    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1600,
      id: 'social-1',
      onUpdate: social1Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1600,
      id: 'social-2',
      onUpdate: social2Move,
    });
    dispatch({
      type: 'registerItem',
      start: 350,
      end: 1600,
      id: 'social-3',
      onUpdate: social3Move,
    });
  }, [dispatch]);

  return (
    <>
      <div className="logo" style={{ maxWidth: '390px' }}>
        <GatsbyImage image={fields.logo_image.childImageSharp.gatsbyImageData} alt={fields.logo_alt} />
      </div>

      <AnimatedChlg style={paralax1}>
        <h2>Creating Brand Awareness for OH SNAP! Pickles</h2>
        <p>{fields.challenge}</p>
      </AnimatedChlg>

      <StyledWrapper>
        <animated.div className="bgsquare" style={paralax3} />

        {/* Testimonial */}
        <animated.div className="left-image animated-image" style={paralax2}>
          <GatsbyImage image={fields.images[0].img.childImageSharp.gatsbyImageData} alt={fields.images[0].alt} />
        </animated.div>

        <animated.div className="right-image animated-image" style={paralax4}>
          <GatsbyImage
            image={fields.images[1].img.childImageSharp.gatsbyImageData}
            alt={fields.images[1].alt}
            style={{ zIndex: 1 }}
          />

          <div className="facebook-scroll-container">
            <animated.div className="image-1" style={paralaxSocial1}>
              <GatsbyImage image={fields.images[2].img.childImageSharp.gatsbyImageData} alt={fields.images[2].alt} />
            </animated.div>
            <animated.div className="image-2" style={paralaxSocial2}>
              <GatsbyImage image={fields.images[3].img.childImageSharp.gatsbyImageData} alt={fields.images[3].alt} />
            </animated.div>
            <animated.div className="image-3" style={paralaxSocial3}>
              <GatsbyImage image={fields.images[4].img.childImageSharp.gatsbyImageData} alt={fields.images[4].alt} />
            </animated.div>
          </div>
        </animated.div>
      </StyledWrapper>

      <StyledApproach>
        <h2>Our Integrated Approach</h2>
        <div className="copyblock" dangerouslySetInnerHTML={{ __html: fields.integrated_approach }} />
      </StyledApproach>

      <GatsbyImage
        image={fields.images[6].img.childImageSharp.gatsbyImageData}
        alt={fields.images[6].alt}
        style={{ maxWidth: 1920, display: 'block', margin: '0 auto' }}
      />

      <StyledWrapper2>
        <animated.div className="left-image animated-image" style={paralax5}>
          <GatsbyImage
            image={fields.images[7].img.childImageSharp.gatsbyImageData}
            alt={fields.images[7].alt}
            style={{ zIndex: 0, position: 'relative' }}
          />
          <animated.div className="animated-image left-two" style={paralax7}>
            <GatsbyImage image={fields.images[8].img.childImageSharp.gatsbyImageData} alt={fields.images[8].alt} />
          </animated.div>
        </animated.div>

        <animated.div className="bgsquare" style={paralax6} />

        <animated.div className="right-image animated-image" style={paralax8}>
          <GatsbyImage image={fields.images[9].img.childImageSharp.gatsbyImageData} alt={fields.images[9].alt} />
        </animated.div>
      </StyledWrapper2>

      <StyledResults align={fields.results_left ? 'left' : 'center'}>
        <CaseStudyResults props={fields} numrows={7} />
      </StyledResults>
    </>
  );
};

export default CaseStudyOhSnap;
